import theme from '../../../assets/theme';
import BaseModal from '../../shared/modals/BaseModal';
import { AddAppointmentModal } from './AddAppointmentModal';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useUserContext } from '../../../context/UserContext';
import axios from 'axios';
import { Endpoints } from '../../../api/endpoints';
import { Appointment, AppointmentAdherencePair } from '../../../models';
import IconButton from '../../shared/IconButton';
import missingAdherenceIcon from '../../../assets/images/orange_circle.svg';
import editIcon from '../../../assets/images/edit-pencil.svg';
import deleteIcon from '../../../assets/images/delete.svg';
import checkMark from '../../../assets/images/check.svg';
import Loader from '../../shared/Loader';
import moment from 'moment';
import format from 'date-fns/format';
import ToolTip from '../ToolTip';
import {
  sendPageView,
  logGAEvent,
} from '../../../shared/services/googleAnalyticsService';
import { DeleteAppointmentModal } from './DeleteAppointmentModal';
import { ErrorToast } from '../../shared/ToastrNotifications';
import { RRuleUtility } from '../../../util/rruleUtil';

interface ViewAppointmentsProps {
  setModalIsOpen: (isOpen: boolean) => void;
  modalIsOpen: boolean;
  patientId: number;
  onCloseButtonClicked?: () => void;
}

enum ModalTypes {
  none,
  edit,
  delete,
}

enum activeViewAppointmentsScreen {
  past,
  upcoming,
}

const ModalBody = styled.div`
  min-height: 450px;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -15px;
`;
const FilterButtons = styled.button`
  border: 1px solid ${props => props.theme.colors.loblolly};
  border-radius: 17px;

  min-width: 120px;
  min-height: 34px;
  font-weight: 800;

  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.loblolly};

  text-transform: uppercase;

  &:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  &.active {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.loblolly};
  }
`;

const Content = styled.div``;

const AppointmentsBlockTitle = styled.p`
  font-weight: 900;
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  text-align: left;
  margin: 19.5px 42px 0px 42px;
  text-transform: uppercase;
  border-bottom: 1px solid ${props => props.theme.colors.athensGray};
`;

const AppointmentBlock = styled.div`
  height: 337.5px;
  margin: 0px 42px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const AppointmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.colors.athensGray};
  width: 100%;
  padding: 10px;
  vertical-align: center;

  &.past-appointments {
    &:first-child {
      align-items: center;
    }
  }
  &.future-appointments {
    justify-content: space-between;
  }
`;

const AppointmentInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const AppointmentIcon = styled.img`
  width: 30px;
  height: 30px;
  padding-right: 8px;
`;

const AppointmentTitle = styled.span`
  color: ${props => props.theme.colors.charcoal};
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
`;

const AppointmentDetails = styled.span`
  color: ${props => props.theme.colors.stoneGray};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
`;

const AppointmentEditButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60px;
  padding-right: 10px;
`;

const ViewAppointmentsModal = (props: ViewAppointmentsProps) => {
  const handleClose = (event?: object, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    gaEvent('close_modal');
    props.setModalIsOpen(false);
  };

  sendPageView('appointmentModal', 'Appointments List');

  const [activeViewAppointmentsView, setActiveViewAppointmentsView] = useState(
    activeViewAppointmentsScreen.past
  );
  const [appointmentDetailsLoading, setAppointmentDetailsLoading] =
    useState(false);
  const [pastAppointments, setPastAppointments] = useState<
    AppointmentAdherencePair[]
  >([]);
  const [futureAppointments, setFutureAppointments] = useState<Appointment[]>(
    []
  );
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState(ModalTypes.none);
  const [savedAppointment, setSavedAppointment] = useState<Appointment>();
  const [dataUpdated, setDataUpdated] = useState(false);
  const { getString } = useUserContext();

  const gaEvent = (value: string) => {
    logGAEvent({
      event: 'eventTracker',
      eventCat: 'appointments',
      eventact: 'click',
      eventLbl: value,
    });
  };

  useEffect(() => {
    if (!props.modalIsOpen) return;

    setAppointmentDetailsLoading(true);
    const currentTime = new Date();
    const numberOfDatesIntoFuture = 30;
    const futureDate = new Date();
    futureDate.setDate(currentTime.getDate() + numberOfDatesIntoFuture);
    setPastAppointments([]);
    setFutureAppointments([]);
    axios
      .get(Endpoints.appointment(props.patientId))
      .then(response => {
        const tempUpcomingArray: Appointment[] = [];
        response.data.forEach((appointment: Appointment) => {
          if (moment(appointment.startDateTime).isAfter()) {
            appointment.date = moment(appointment.startDateTime).toDate();
            tempUpcomingArray.push(appointment);
          } else if (appointment.rrule) {
            const nextOccurrence = RRuleUtility.nextAppointmentDate(
              appointment.startDateTime,
              appointment.rrule,
              appointment.endDateTime || ''
            );
            appointment.date = moment(nextOccurrence).toDate();
            tempUpcomingArray.push(appointment);
          }
        });
        setFutureAppointments(tempUpcomingArray);
      })
      .catch(error => {
        ErrorToast(error);
      });

    axios
      .post(Endpoints.appointmentMetric(props.patientId), {
        startDateTime: new Date(0).toISOString(),
        endDateTime: futureDate,
      })
      .then(response => {
        const tempPastArray: AppointmentAdherencePair[] = [];
        response.data.forEach((appointment: AppointmentAdherencePair) => {
          if (
            moment(appointment.appointment.startDateTime).isBefore(moment.now())
          ) {
            tempPastArray.push(appointment);
          }
        });
        setPastAppointments(tempPastArray);
      })
      .catch(error => {
        ErrorToast(error);
      })
      .finally(() => {
        setAppointmentDetailsLoading(false);
      });
  }, [
    props.modalIsOpen,
    props.patientId,
    dataUpdated,
    activeViewAppointmentsView,
  ]);

  const setActive = (newView: activeViewAppointmentsScreen) => {
    setActiveViewAppointmentsView(newView);
  };

  const RenderModal = () => {
    switch (modalType) {
      case ModalTypes.none:
        return null;
      case ModalTypes.edit:
        return (
          <AddAppointmentModal
            setModalIsOpen={() => setModalType(ModalTypes.none)}
            modalIsOpen={true}
            patientId={props.patientId}
            prefillData={savedAppointment}
            refreshTable={() => setDataUpdated(!dataUpdated)} // force rerender
          />
        );
      case ModalTypes.delete:
        return (
          <DeleteAppointmentModal
            setModalIsOpen={() => setModalType(ModalTypes.none)}
            modalIsOpen={true}
            patientId={props.patientId}
            appointmentId={savedAppointment ? savedAppointment.id : ''}
            refreshTable={() => setDataUpdated(!dataUpdated)} // force rerender
          />
        );
    }
  };

  const viewAppointmentsContent = () => {
    switch (activeViewAppointmentsView) {
      case activeViewAppointmentsScreen.past:
        return (
          <Content>
            <AppointmentsBlockTitle data-testid='title'>
              {getString(
                'appointmentListModalComponent.label.pastAppointment'
              ) + `(${pastAppointments.length})`}
            </AppointmentsBlockTitle>
            <AppointmentBlock>
              {pastAppointments.map((appointment: AppointmentAdherencePair) => {
                return (
                  <AppointmentContainer
                    key={appointment.appointment.id}
                    className='past-appointments'
                    data-testid='appointment'>
                    <AppointmentIcon
                      src={
                        appointment.adherence[0].didAdhere
                          ? checkMark
                          : missingAdherenceIcon
                      }
                      alt='adherence Icon'
                    />
                    <ToolTip
                      text={appointment.appointment.title}
                      toolType='modal'
                      anchorEl={
                        <AppointmentInnerContainer>
                          <AppointmentTitle>
                            {appointment.appointment.title}
                          </AppointmentTitle>
                          <AppointmentDetails>
                            {format(
                              new Date(appointment.appointment.startDateTime),
                              'MMM dd, yyyy | hh:mm a'
                            )}
                          </AppointmentDetails>
                        </AppointmentInnerContainer>
                      }
                    />
                  </AppointmentContainer>
                );
              })}
            </AppointmentBlock>
          </Content>
        );
      case activeViewAppointmentsScreen.upcoming:
        return (
          <Content>
            <AppointmentsBlockTitle data-testid='title'>
              {getString(
                'appointmentListModalComponent.label.upcomingAppointment'
              ) + `(${futureAppointments.length})`}
            </AppointmentsBlockTitle>
            <AppointmentBlock>
              {futureAppointments.map((appointment: Appointment) => {
                return (
                  <AppointmentContainer
                    className='future-appointments'
                    key={appointment.id}
                    data-testid='appointment'>
                    <ToolTip
                      text={appointment.title}
                      toolType='modal'
                      anchorEl={
                        <AppointmentInnerContainer>
                          <AppointmentTitle>
                            {appointment.title}
                          </AppointmentTitle>
                          <AppointmentDetails>
                            {format(
                              new Date(appointment.startDateTime),
                              'MMM dd, yyyy | hh:mm a'
                            )}
                          </AppointmentDetails>
                        </AppointmentInnerContainer>
                      }
                    />

                    <AppointmentEditButtonsContainer>
                      <IconButton
                        imgSrc={editIcon}
                        size={theme.iconButtonSizes.sm}
                        alt='edit icon'
                        onClick={() => {
                          gaEvent('edit_appointment');
                          setSavedAppointment(appointment);
                          setModalType(ModalTypes.edit);
                        }}
                      />
                      <IconButton
                        imgSrc={deleteIcon}
                        size={theme.iconButtonSizes.sm}
                        alt='delete icon'
                        onClick={() => {
                          gaEvent('appointment_delete');
                          setModalType(ModalTypes.delete);
                          setSavedAppointment(appointment);
                        }}
                      />
                    </AppointmentEditButtonsContainer>
                  </AppointmentContainer>
                );
              })}
            </AppointmentBlock>
          </Content>
        );
      default:
        null;
    }
  };

  return (
    <BaseModal
      onCloseButtonClicked={() => props.onCloseButtonClicked?.()}
      handleClose={handleClose}
      modalIsOpen={props.modalIsOpen}
      setModalIsOpen={props.setModalIsOpen}
      modalWidth={theme.modalWidths.lg}>
      {savedAppointment ? RenderModal() : null}
      <ModalBody>
        <ButtonContainer>
          <FilterButtons
            className={
              activeViewAppointmentsView === activeViewAppointmentsScreen.past
                ? 'active'
                : ''
            }
            onClick={() => {
              setActive(activeViewAppointmentsScreen.past);
              gaEvent('past');
            }}>
            {getString('appointmentListModalComponent.button.past')}
          </FilterButtons>
          <FilterButtons
            className={
              activeViewAppointmentsView ===
              activeViewAppointmentsScreen.upcoming
                ? 'active'
                : ''
            }
            onClick={() => {
              setActive(activeViewAppointmentsScreen.upcoming);
              gaEvent('upcoming');
            }}
            data-testid='upcoming-button'>
            {getString('appointmentListModalComponent.button.upcoming')}
          </FilterButtons>
        </ButtonContainer>
        {appointmentDetailsLoading ? <Loader /> : viewAppointmentsContent()}
      </ModalBody>
    </BaseModal>
  );
};

export { ViewAppointmentsModal };
