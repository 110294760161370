import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {
  format,
  formatISO,
  parse,
  parseISO,
  formatDistanceToNow,
} from 'date-fns';
import Layout from '../main/Layout';
import AdherenceLegend from './AdherenceLegend';
import ToolTip from './ToolTip';
import Pager from './Pager';
import DailyAdherence from './DailyAdherence';
import Loader from '../shared/Loader';
import { useNavigate, useParams, NavigateOptions } from 'react-router-dom';
import { useUserContext, getString } from '../../context/UserContext';
import { Endpoints } from '../../api/endpoints';
import { Patient, ConnectionStatus } from '../../models';
import { PatientsListResponse } from '../../api/models';
import { getEnumCaseName } from '../../util/enum';
import { IconButton } from '../shared/IconButton';
import closeFilterImage from '../../assets/images/close-filter.svg';
import patientsWithAlertsImage from '../../assets/images/patientswithalerts.svg';
import patientsWithAlertsWhiteImage from '../../assets/images/patientswithalerts_white.svg';
import disconnectedImage from '../../assets/images/disconnected.svg';
import disconnectedWhiteImage from '../../assets/images/disconnected_white.svg';
import loadingImage from '../../assets/images/loading.svg';
import declinedPatientIcon from '../../assets/images/decline_x.svg';
import declinedPatientHoverIcon from '../../assets/images/decline_x_hover.svg';
import acceptPatientIcon from '../../assets/images/checkmark_circle.svg';
import acceptPatientHoverIcon from '../../assets/images/checkmark_circle_hover.svg';
import alertImage from '../../assets/images/alert-1.svg';
import {
  AcceptConnectionModal,
  DeclineConnectionModal,
} from './modals/ConnectionModal';
import arrowImage from '../../assets/images/cal_arrow_left.svg';
import infoImage from '../../assets/images/info.svg';
import { Popper, ClickAwayListener } from '@mui/base';
import { logGAEvent } from '../../shared/services/googleAnalyticsService';
import { ErrorToast } from '../shared/ToastrNotifications';
import SearchField from '../shared/SearchField';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  h1 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.charcoal};
    font-size: 20px;
    font-weight: 900;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  gap: 8px;
  margin-right: 40px;
`;

const FilterItemContainer = styled.div`
  display: flex;
  flex-grow: 2;
  gap: 10px;
  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    // forces filter to move to next row
    width: 100%;
    order: 3;
  }
`;

const FilterItem = styled.button`
  color: ${props => props.theme.colors.pacificBlue};
  border: 1px solid ${props => props.theme.colors.hawkesBlue};
  padding: 4px 8px;
  padding-right: 20px;
  border-radius: 8px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  background: transparent url(${closeFilterImage}) no-repeat right center;
`;

const PageBody = styled.div`
  display: flex;
  border-top: 1px solid ${props => props.theme.colors.hawkesBlue};
  margin-bottom: 40px;
  @media (max-width: ${props => props.theme.breakPoints.lg}) {
    flex-direction: column;
  }
  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 16px;
  }
`;
const Icon = styled.span`
  background: url(${infoImage}) no-repeat;
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  bottom: -9px;
  margin-bottom: -1px;
  margin-top: 9px;
  padding-right: 1px;
  cursor: pointer;
`;

const SideNav = styled.div<{ $isExpanded: boolean }>`
  position: relative;
  button {
    color: ${props => props.theme.colors.charcoal};
    background-color: ${props => props.theme.colors.white};
    font-weight: 900;
    span {
      text-align: left;
      text-transform: uppercase;
    }
  }
  menu {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    @media (max-width: ${props => props.theme.breakPoints.lg}) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    @media (min-width: ${props => props.theme.breakPoints.lg}) {
      flex: 0 0 24%;
    }
  }
  menu button {
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid ${props => props.theme.colors.athensGray};
    border-top: 0;
    padding: 0 0 0 20px;
    height: 90px;
    &.active {
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.curiousBlue};
    }
    @media (max-width: ${props => props.theme.breakPoints.lg}) {
      flex-direction: row;
      flex-wrap: wrap;
      height: 50px;
      margin: 5px;
      padding: 0 10px;
      border: none;
    }
    @media (min-width: ${props => props.theme.breakPoints.lg}) {
      &.secondary {
        font-weight: 700;
        margin-left: 12px;
        height: 56px;
      }
      &.active::after {
        content: ' ';
        border-bottom: 15px solid transparent;
        border-right: 15px solid ${props => props.theme.colors.solitude};
        border-top: 15px solid transparent;
        width: 0;
        height: 0;
        margin: auto -1px auto auto;
      }
    }
    & > .itemCount {
      background-color: ${props => props.theme.colors.curiousBlue};
      border: 4px solid transparent;
      border-radius: 100%;
      color: ${props => props.theme.colors.white};
      width: 25px;
      height: 25px;
      min-width: 25px;
      margin-left: 10%;
      margin-right: 5%;
      &.active {
        color: ${props => props.theme.colors.curiousBlue};
        background-color: ${props => props.theme.colors.white};
        border-color: ${props => props.theme.colors.white};
      }
      @media (max-width: ${props => props.theme.breakPoints.lg}) {
        display: none;
      }
    }

    & > .loadingIcon {
      @media (max-width: ${props => props.theme.breakPoints.lg}) {
        display: none;
      }
      min-width: 25px;
      margin-left: 10%;
      margin-right: 5%;

      img {
        width: 33px;
        height: 33px;
      }
    }
  }
  // mobile view
  button.mobile-dropdown {
    @media (min-width: ${props => props.theme.breakPoints.md}) {
      display: none;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 8px 16px;
    font-size: 20px;
    span.caret::after {
      border-bottom: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid;
      content: '';
      display: inline-block;
      vertical-align: 16px;
    }
  }
  @media (max-width: ${props => props.theme.breakPoints.md}) {
    margin: 0 16px;
    menu {
      position: absolute;
      width: 100%;
      transform: translateY(2px);
      display: ${props => (props.$isExpanded ? 'flex' : 'none')};
      z-index: 10;
      flex-direction: column;
      gap: 0px;
      background-color: ${props => props.theme.colors.white};
      border: 1px solid ${props => props.theme.colors.loblolly};
      border-radius: 6px;
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.175);
      padding: 10px 0;
      button {
        margin: 0;
        padding: 0 16px;
        border: none;
      }
    }
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  padding: 16px 0;
  @media (min-width: ${props => props.theme.breakPoints.lg}) {
    padding: 16px;
    padding-left: 32px;
  }
`;

const ListContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-weight: 700;
    text-transform: uppercase;
    color: ${props => props.theme.colors.loblolly};
  }
`;

const ListContainerFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Table = styled.table`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.athensGray};
  border-collapse: separate;
  border-radius: 20px;
  text-transform: uppercase;
  thead {
    color: ${props => props.theme.colors.echoBlue};
    p {
      margin: 0;
      font-weight: 900;
    }
    span {
      border-bottom: 1px dashed ${props => props.theme.colors.echoBlue};
    }
  }
  thead th {
    vertical-align: bottom;
  }
  tbody tr {
    cursor: pointer;
  }
  td,
  th {
    border-bottom: 1px solid ${props => props.theme.colors.athensGray};
    border-right: 1px solid ${props => props.theme.colors.ghostWhite};
    vertical-align: middle;
    text-align: center;
    padding: 10px;
    width: 22%;
    &.left {
      text-align: left;
      padding-left: 40px;
    }
  }
  th:nth-child(3),
  th:nth-child(4),
  tr td:nth-child(3),
  tr td:nth-child(4) {
    border-right: none;
  }
  th:last-child,
  tr td:last-child {
    border-right: none;
    width: 10%;
  }
  tr:last-child td {
    border-bottom: none;
  }
  td p {
    font-weight: 500;
    margin: 0;
    color: ${props => props.theme.colors.charcoal};
    &.red {
      color: ${props => props.theme.colors.amaranth};
    }
    &.yellow {
      color: ${props => props.theme.colors.texasRose};
    }
    &.grey {
      color: ${props => props.theme.colors.loblolly};
    }
  }
`;

const ArrowImg = styled.img`
  margin-left: 4px;
`;
const SortByButton = styled.button`
  margin-left: 2px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-size: 16px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const SortPopperContent = styled.ul`
  position: relative;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.hawkesBlue};
  border-radius: 5px;
  list-style: none;
  width: 17rem;
  left: 22px;
  top: -12px;
  min-width: 12.5rem;
  margin: 0 6px;
  padding: 10px 4px;
  &::before,
  &::after {
    border: solid transparent;
    content: ' ';
    width: 0;
    height: 0;
    bottom: 100%;
    left: 89%;
    position: absolute;
    pointer-events: none;
  }
  &::before {
    border-width: 13px;
    margin-left: -13px;
    border-bottom-color: ${props => props.theme.colors.hawkesBlue};
  }
  &::after {
    border-width: 11px;
    margin-left: -11px;
    border-bottom-color: ${props => props.theme.colors.white};
  }
  button {
    cursor: pointer;
    padding: 6px 18px;
    text-transform: uppercase;
    font-weight: 900;
    color: ${props => props.theme.colors.loblolly};
    margin-right: 30px;
    &.selected {
      color: ${props => props.theme.colors.charcoal};
    }
  }
  hr {
    border: none;
    background-color: ${props => props.theme.colors.hawkesBlue};
    height: 1px;
  }
`;

type NavOption = {
  key: string;
  filterKey?: string;
  filter?: string;
  image?: string;
  altImage?: string;
  isSecondary?: boolean;
  itemCount?: number;
};

enum SortBy {
  lastName = 'lastName',
  lastActive = 'lastActive',
  medicationAdherencePercentage = 'medicationAdherencePercentage',
}

enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

enum ModalType {
  none,
  acceptModal,
  declineModal,
}

const navOptions: NavOption[] = [
  {
    key: 'patientSidenavComponent.option.allPatients',
  },
  {
    key: 'patientSidenavComponent.option.patientsWithAlerts',
    filterKey: 'patientSidenavComponent.option.patientsWithAlertsFilter',
    filter: 'alert',
    image: patientsWithAlertsImage,
    altImage: patientsWithAlertsWhiteImage,
    isSecondary: true,
  },
  {
    key: 'patientSidenavComponent.option.pendingDisconnect',
    filterKey: 'patientSidenavComponent.option.pendingDisconnectFilter',
    filter: 'pending-disconnect',
    image: disconnectedImage,
    altImage: disconnectedWhiteImage,
    isSecondary: true,
  },
  {
    key: 'patientSidenavComponent.option.connectionRequests',
    filterKey: 'patientSidenavComponent.option.connectionRequestsFilter',
    filter: 'pending-approval',
    itemCount: 0,
  },
];

export const PatientList = () => {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const { user } = useUserContext();
  const { page } = useParams();
  const [currentPage, setCurrentPage] = useState(() => parseInt(page || ''));

  const [navOptionIndex, setNavOptionIndex] = useState(0);
  const navOption = navOptions[navOptionIndex];

  const [sortBy, setSortBy] = useState(SortBy.lastName);
  const [sortDirection, setSortDirection] = useState(SortDirection.asc);
  const [tagFilters, setTagFilters] = useState<string[]>([]);

  const [data, setData] = useState<PatientsListResponse>();
  const [tagSearchResults, setTagSearchResults] = useState<string[]>();
  const [sortPopperAnchor, setSortPopperAnchor] = useState<HTMLElement>();
  const isSortPopperOpen = Boolean(sortPopperAnchor);
  const sideNavRef = useRef<HTMLDivElement>(null);
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.none);
  const [activePatient, setActivePatient] = useState<Patient | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingConnectionRequests, setIsLoadingConnectionRequests] =
    useState(true);
  const [connectionListUpdated, setConnectionListUpdated] = useState(false);

  const [siteId, setSiteId] = useState<number>();
  const sortByMap = {
    [SortBy.lastName]: 'name',
    [SortBy.lastActive]: 'lastActive',
    [SortBy.medicationAdherencePercentage]: 'medicationAdherence',
  };

  useEffect(() => {
    // collapse navigation when user clicks outside container
    // (only applies to mobile view)
    const handleClickOutside = (event: MouseEvent) => {
      if (!sideNavRef.current?.contains(event.target as Node)) {
        setIsSideNavExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sideNavRef]);

  const tagCallback = useCallback(
    (q: string) => {
      if (!q.length) return;
      axios
        .get(Endpoints.tags, { params: { q } })
        .then(response => {
          const tags: string[] = response.data.tags;
          setTagSearchResults(tags.filter(tag => !tagFilters.includes(tag)));
        })
        .catch(error => {
          ErrorToast(error);
        });
    },
    [tagFilters]
  );

  const connectionAcceptButtonClicked = (patient: Patient, siteId: number) => {
    axios
      .post(Endpoints.confirmPatientConnection(patient.id, siteId), {
        confirm: true,
      })
      .then(() => {
        setActivePatient(patient);
        setModalType(ModalType.acceptModal);
        setShowModal(true);
      })
      .catch(error => {
        ErrorToast(error);
      });
  };

  const connectionDeclineButtonClicked = (patient: Patient, id: number) => {
    setActivePatient(patient);
    setSiteId(id);
    setModalType(ModalType.declineModal);
    setShowModal(true);
  };

  const activeModal = () => {
    if (activePatient === null) return null;

    switch (modalType) {
      case ModalType.acceptModal:
        return (
          <AcceptConnectionModal
            patient={activePatient}
            setModalIsOpen={setShowModal}
            modalIsOpen={showModal}
            siteId={siteId ?? 0}
            refreshTable={() => {
              setConnectionListUpdated(!connectionListUpdated);
            }}
          />
        );
      case ModalType.declineModal:
        return (
          <DeclineConnectionModal
            patient={activePatient}
            setModalIsOpen={setShowModal}
            modalIsOpen={showModal}
            siteId={siteId ?? 0}
            refreshTable={() =>
              setConnectionListUpdated(!connectionListUpdated)
            }
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  const navigateToPage = (page: number, options?: NavigateOptions) => {
    // setting page state forces an api refresh
    setCurrentPage(page);
    navigateRef.current(`/patient/page/${page}`, options);
  };

  useEffect(() => {
    const controller = new AbortController();
    const cleanup = () => {
      controller.abort();
    };

    if (isNaN(currentPage) || currentPage < 1) {
      // invalid page number
      navigateToPage(1, { replace: true });
      return cleanup;
    }

    setData(undefined);
    setIsLoading(true);
    setIsLoadingConnectionRequests(true);
    axios
      .get(Endpoints.patients(), {
        signal: controller.signal,
        params: {
          page: currentPage,
          adherence: true,
          currentDateTime: formatISO(new Date()),
          sort: [sortBy, sortDirection],
          filter: navOption.filter,
          tags: tagFilters,
        },
        paramsSerializer: {
          indexes: false, // use brackets with indexes
        },
      })
      .then(response => {
        const patients: PatientsListResponse = response.data;
        if (!patients.users.length && currentPage !== 1) {
          // user navigated beyond end of list
          navigateToPage(1, { replace: true });
          return;
        }
        setData(patients);
      })
      .catch(error => ErrorToast(error))
      .finally(() => setIsLoading(false));

    axios
      .get(Endpoints.patients(), {
        signal: controller.signal,
        params: {
          page: 1,
          filter: 'pending-approval',
          currentDateTime: formatISO(new Date()),
        },
      })
      .then(response => {
        // connection request count is total users with the 'pending-approval' filter
        const connectionCount = response.data.totalUsers;
        navOptions[3].itemCount = connectionCount;
      })
      .catch(error => ErrorToast(error))
      .finally(() => setIsLoadingConnectionRequests(false));

    return cleanup;
  }, [
    currentPage,
    sortBy,
    sortDirection,
    navOptionIndex,
    navOption.filter,
    tagFilters,
    connectionListUpdated,
  ]);

  const PatientTable = () => {
    const ConnectionStatusText = (props: { patient: Patient }) => {
      const status = props.patient.sites[0].connectionStatus?.status;
      if (!status) {
        return null;
      }
      const className = (() => {
        switch (status) {
          case ConnectionStatus.pendingDisconnection:
          case ConnectionStatus.pendingDeletion:
          case ConnectionStatus.expired:
            return 'red';
          case ConnectionStatus.pending:
            return 'yellow';
          default:
            return '';
        }
      })();
      const stringKey = getEnumCaseName(status, ConnectionStatus);
      return (
        <p className={className}>
          {getString(`patientUIComponent.tableBody.${stringKey}`)}
        </p>
      );
    };

    const LastActiveText = (props: { patient: Patient }) => {
      const lastActive = props.patient.lastActive;
      if (!lastActive) {
        return null;
      }
      return (
        <p className='grey'>{`${getString(
          'patientUIComponent.tableBody.lastActive'
        )} ${formatDistanceToNow(parseISO(lastActive))}`}</p>
      );
    };

    const DaysUntilDisconnectText = (props: { patient: Patient }) => {
      const daysUntilDisconnection = props.patient.daysUntilDisconnection;
      if (
        navOption.filter !== 'pending-disconnect' ||
        !(daysUntilDisconnection || daysUntilDisconnection === 0)
      ) {
        return null;
      }
      return (
        <p className='grey'>
          {getString('patientUIComponent.tableBody.patientVisibility')}{' '}
          <strong>{`${daysUntilDisconnection} ${getString(
            'patientUIComponent.tableBody.daysLeft'
          )}`}</strong>
        </p>
      );
    };

    const hcpHasPAHCarePlan = () => {
      const carePlans = user?.organization.carePlanTemplates ?? [];
      return !!carePlans.find(
        c => c.title === 'Pulmonary Arterial Hypertension Care Module'
      );
    };

    return (
      <Table>
        <thead>
          <tr>
            <th className='left'>
              <p>{getString('patientUIComponent.tableHeader.patient')}</p>
            </th>
            <th>
              {hcpHasPAHCarePlan() ? (
                <p>
                  {getString('patientUIComponent.tableHeader.revealLite2')}
                  <br />
                  {getString('patientUIComponent.tableHeader.riskScore')}
                </p>
              ) : (
                <p>
                  {getString('patientUIComponent.tableHeader.dailyMedication')}
                  <br />
                  {getString('patientUIComponent.tableHeader.adherence')}
                </p>
              )}
            </th>
            <th>
              <ToolTip
                text={getString(
                  'patientUIComponent.tooltip.medicationAdherence'
                )}
                toolType='table'
                anchorEl={
                  <p
                    onFocus={() => null}
                    onMouseOver={() =>
                      logGAEvent({
                        event: 'eventTracker',
                        eventCat: 'patients',
                        eventAct: 'hover',
                        eventLbl: 'medication_adherence',
                      })
                    }>
                    {getString('patientUIComponent.tableHeader.medication')}
                    <br />
                    <span>
                      {getString('patientUIComponent.tableHeader.adherence')}
                    </span>
                  </p>
                }
              />
            </th>
            <th>
              <ToolTip
                text={getString(
                  'patientUIComponent.tooltip.appointmentAttendance'
                )}
                toolType='table'
                anchorEl={
                  <p
                    onFocus={() => null}
                    onMouseOver={() =>
                      logGAEvent({
                        event: 'eventTracker',
                        eventCat: 'patients',
                        eventAct: 'hover',
                        eventLbl: 'appointment_attendance',
                      })
                    }>
                    {getString('patientUIComponent.tableHeader.appointment')}
                    <br />
                    <span>
                      {getString('patientUIComponent.tableHeader.attendance')}
                    </span>
                  </p>
                }
              />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.users.map((patient, index) => (
            <tr
              key={index}
              onClick={() => {
                logGAEvent({
                  event: 'eventTracker',
                  eventCat: 'viewPatient',
                  eventAct: 'view',
                  eventLbl: patient.id,
                });
                navigate(`/patient/${patient.id}`);
              }}>
              <td className='left'>
                <p>
                  <strong>
                    {[patient.lastName, patient.firstName].join(', ')}
                  </strong>
                </p>
                <ConnectionStatusText patient={patient} />
                <LastActiveText patient={patient} />
                <DaysUntilDisconnectText patient={patient} />
              </td>
              <td>
                {hcpHasPAHCarePlan() ? (
                  <p>{patient.revealLite2RiskScore}</p>
                ) : (
                  <DailyAdherence calendar={patient.dailyAdherence} />
                )}
              </td>
              <td>
                <p>{patient.medicationAdherence.percentage}%</p>
              </td>
              <td>
                <p>
                  {[
                    patient.appointmentAdherence.adhered,
                    patient.appointmentAdherence.total,
                  ].join('/')}
                </p>
              </td>
              <td>
                {patient.isNonAdherent && <img src={alertImage} alt='alert' />}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const ConnectionRequestsTable = () => {
    return (
      <Table>
        <thead>
          <tr>
            <th className='left'>
              <p>{getString('patientUIComponent.tableHeader.patient')}</p>
            </th>
            <th>
              <p>{getString('patientUIComponent.tableBody.dateOfBirth')}</p>
            </th>
            <th>
              <p>{getString('patientUIComponent.tableBody.contactInfo')}</p>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.users.map(patient => {
            return patient.sites.map(site => {
              return (
                <tr key={`${patient.id}-${site.id}`}>
                  <td className='left'>
                    <p>{patient.id}</p>
                    <p>
                      <strong>
                        {[patient.lastName, patient.firstName].join(', ')}
                      </strong>
                    </p>
                    <p>{site.name}</p>
                    <p className='grey'>
                      {'Requested ' +
                        format(
                          parseISO(site.dateRequested ?? ''),
                          ' MMM do, y'
                        )}
                    </p>
                  </td>
                  <td>
                    <p>
                      {format(
                        parse(patient.dateOfBirth, 'yyyy-MM-dd', new Date()),
                        'MM/dd/yyyy'
                      )}
                    </p>
                  </td>
                  <td>
                    <p>{patient.email}</p>
                  </td>
                  <td>
                    <ButtonContainer>
                      <IconButton
                        imgSrc={declinedPatientIcon}
                        alt='decline icon'
                        onHoverImage={declinedPatientHoverIcon}
                        onClick={() =>
                          connectionDeclineButtonClicked(patient, site.id)
                        }
                      />
                      <IconButton
                        imgSrc={acceptPatientIcon}
                        alt='accept icon'
                        onHoverImage={acceptPatientHoverIcon}
                        onClick={() =>
                          connectionAcceptButtonClicked(patient, site.id)
                        }
                      />
                    </ButtonContainer>
                  </td>
                </tr>
              );
            });
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <Layout>
      <PageContainer>
        <Header>
          <HeaderLeft>
            <h1>{getString('patientListComponent.title.patients')}</h1>
            <ToolTip
              text={getString(
                'connectionRequestModalComponent.tsMessages.importantInfoTooltip'
              )}
              toolType='icon'
              anchorEl={<Icon />}
            />
          </HeaderLeft>
          <FilterItemContainer>
            {navOptionIndex > 0 && (
              <FilterItem
                onClick={() => {
                  setNavOptionIndex(0);
                  setTagFilters([]);
                  navigateToPage(1);
                }}>
                {getString(navOption.filterKey || '')}
              </FilterItem>
            )}
            {tagFilters.map((tag: string) => (
              <FilterItem
                key={tag}
                onClick={() => {
                  setTagSearchResults(undefined);
                  setTagFilters(tagFilters.filter((t: string) => t !== tag));
                }}>
                {tag}
              </FilterItem>
            ))}
          </FilterItemContainer>
          <SearchField
            handleSearchCallback={tagCallback}
            output={tagSearchResults}
            clickEvent={index => {
              if (tagSearchResults) {
                const selectedTag = tagSearchResults[index];
                setTagSearchResults(undefined);
                setTagFilters([...tagFilters, selectedTag]);
              }
            }}
            placeholder={getString(
              'searchPatientTagsComponent.instructions.placeholder'
            )}
          />
        </Header>
        <PageBody>
          {activeModal()}
          <SideNav ref={sideNavRef} $isExpanded={isSideNavExpanded}>
            <button
              className='mobile-dropdown'
              onClick={() => setIsSideNavExpanded(!isSideNavExpanded)}>
              <span>{getString(navOption.key)}</span>
              <span className='caret' />
            </button>
            <menu>
              {navOptions.map((option, index) => {
                const title = getString(option.key);
                const isActive = navOptionIndex === index;
                const classNames = [];
                if (option.isSecondary) classNames.push('secondary');
                if (isActive) classNames.push('active');
                return (
                  <button
                    key={option.key}
                    className={classNames.join(' ')}
                    onClick={() => {
                      setIsSideNavExpanded(false);
                      setNavOptionIndex(index);
                      setTagFilters([]);
                      navigateToPage(1);
                    }}>
                    {option.image && (
                      <img
                        src={isActive ? option.altImage : option.image}
                        alt={title}
                      />
                    )}
                    <span>{title}</span>
                    {typeof option.itemCount === 'number' ? (
                      isLoadingConnectionRequests ? (
                        <div className='loadingIcon'>
                          <img src={loadingImage} alt='loading' />
                        </div>
                      ) : (
                        <div
                          className={`itemCount ${isActive ? 'active' : null}`}>
                          {option.itemCount}
                        </div>
                      )
                    ) : null}
                  </button>
                );
              })}
            </menu>
          </SideNav>
          <ListContainer>
            <ListContainerHeader>
              <div>
                {`${isLoading || !data ? '' : data?.totalUsers} ${getString(
                  'patientListComponent.title.totalPatients'
                )}`}
              </div>
              <div>
                {getString('patientListComponent.sort.sortby')}
                <>
                  <SortByButton
                    onClick={e => {
                      setSortPopperAnchor(
                        !sortPopperAnchor ? e.currentTarget : undefined
                      );
                    }}>
                    {getString(
                      `patientListComponent.sort.${sortByMap[sortBy]}`
                    )}
                    <ArrowImg
                      src={arrowImage}
                      alt=''
                      style={{
                        transform: isSortPopperOpen
                          ? 'rotate(90deg)'
                          : 'rotate(270deg)',
                      }}
                    />
                  </SortByButton>
                  <Popper
                    open={isSortPopperOpen}
                    anchorEl={sortPopperAnchor}
                    placement='bottom-end'
                    modifiers={[
                      {
                        name: 'offset',
                        options: {
                          offset: [12, 14],
                        },
                      },
                    ]}>
                    <ClickAwayListener
                      onClickAway={() => setSortPopperAnchor(undefined)}>
                      <SortPopperContent>
                        {Object.values(SortBy)
                          .filter(value => typeof value === 'string')
                          .map((value, index) => (
                            <button
                              key={index}
                              className={sortBy === value ? 'selected' : ''}
                              onClick={() => {
                                setSortPopperAnchor(undefined);
                                setSortBy(value as SortBy);
                              }}>
                              {getString(
                                `patientListComponent.sort.${sortByMap[value]}`
                              )}
                            </button>
                          ))}
                        <hr />
                        {Object.keys(SortDirection)
                          .filter(i => isNaN(Number(i)))
                          .map((value, index) => (
                            <button
                              style={{
                                fontWeight: 500,
                              }}
                              key={index}
                              onClick={() => {
                                setSortDirection(value as SortDirection);
                              }}
                              className={
                                sortDirection === value ? 'selected' : ''
                              }>
                              {getString(
                                `patientListComponent.sort.${value}endingOrder`
                              )}
                            </button>
                          ))}
                      </SortPopperContent>
                    </ClickAwayListener>
                  </Popper>
                </>
              </div>
            </ListContainerHeader>
            {isLoading || !data ? (
              <Loader />
            ) : (
              <>
                {navOptionIndex !== 3 ? (
                  <PatientTable />
                ) : (
                  <ConnectionRequestsTable />
                )}
                <ListContainerFooter>
                  <Pager
                    currentPage={currentPage}
                    itemsPerPage={data?.countPerPage || 0}
                    totalItems={data?.totalUsers || 0}
                    onPageChange={(next, range) => {
                      const labelPrefix = next > currentPage ? 'next' : 'back';
                      logGAEvent({
                        event: 'eventTracker',
                        eventCat: 'patients',
                        eventAct: 'click',
                        eventLbl: `${labelPrefix}_to_[${range.lowerBound}-${
                          range.upperBound
                        } of ${data?.totalUsers || 0}]`,
                      });
                      navigateToPage(next);
                    }}
                  />
                </ListContainerFooter>
              </>
            )}
          </ListContainer>
        </PageBody>
        {isLoading || !data || (
          <>
            <AdherenceLegend
              alertLevelDays={
                data && data.users.length > 0
                  ? data.users[0].sites[0].alertLevelDays
                  : 3
              }
            />
            <p style={{ textAlign: 'center' }}>
              {getString('disclaimer.footer')}
            </p>
          </>
        )}
      </PageContainer>
    </Layout>
  );
};

export default PatientList;
