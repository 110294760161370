import styled from 'styled-components';

interface MaskedComponentProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  mask: RegExp;
  placeholder?: string;
  className?: string;
  id?: string;
}
const Input = styled.input`
  border: 1px solid ${props => props.theme.colors.mystic};
  border-radius: 4px;
  color: ${props => props.theme.colors.charcoal};
  font-weight: 600;
  font-family: 'Brandon';
  font-size: 16px;
  height: 24px;
  padding: 10px;
`;

const MaskedInput = (props: MaskedComponentProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // check if input matches mask
    if (props.mask.test(e.target.value)) {
      props.setValue(e.target.value);
    }
  };
  return (
    <Input
      placeholder={props.placeholder}
      className={props.className}
      id={props.id}
      value={props.value}
      onChange={handleChange}
    />
  );
};

export { MaskedInput };
